var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4 py-4"},[(_vm.editMode)?_c('v-card-title',[_vm._v(" Edit Ingestion ")]):_c('v-card-title',[_vm._v(" Create Ingestion ")]),(_vm.config.datasetId && _vm.config.tableId)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.config.datasetId + ':' + _vm.config.tableId))]):_vm._e(),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',[_c('ValidationProvider',{attrs:{"name":"Dataset Id","rules":"required|max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"Dataset Id","readonly":_vm.editMode,"items":_vm.datasets,"item-text":"datasetId","item-value":"datasetId","error-messages":errors,"required":""},on:{"change":_vm.loadTables},model:{value:(_vm.config.datasetId),callback:function ($$v) {_vm.$set(_vm.config, "datasetId", $$v)},expression:"config.datasetId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Table Id","rules":"required|max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"Table Id","readonly":_vm.editMode,"items":_vm.tables,"item-text":"tableId","item-value":"tableId","error-messages":errors,"required":""},model:{value:(_vm.config.tableId),callback:function ($$v) {_vm.$set(_vm.config, "tableId", $$v)},expression:"config.tableId"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":"Truncate destination table"},model:{value:(_vm.config.truncate),callback:function ($$v) {_vm.$set(_vm.config, "truncate", $$v)},expression:"config.truncate"}}),_c('ValidationProvider',{attrs:{"name":"Metadata","rules":"metadataRule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticStyle:{"font-family":"monospace","font-size":"12px"},attrs:{"label":"Metadata","error-messages":errors,"hint":"Define the metadata"},model:{value:(_vm.config.metadata),callback:function ($$v) {_vm.$set(_vm.config, "metadata", $$v)},expression:"config.metadata"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Transformation SQL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticStyle:{"font-family":"monospace","font-size":"12px"},attrs:{"label":"Transformation SQL","error-messages":errors,"hint":"Define the SQL transformation query","required":""},model:{value:(_vm.config.transformSql),callback:function ($$v) {_vm.$set(_vm.config, "transformSql", $$v)},expression:"config.transformSql"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")]),(_vm.config.tableExists)?_c('v-btn',{attrs:{"text":"","color":"purple darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},[_vm._v(" Validate ")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":"green darken-1"},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_vm._v("Save")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }